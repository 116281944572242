import "react-app-polyfill/stable";
import "core-js";
import React, {useEffect, useState} from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./keycloak";
import { ErrorBoundary } from "react-error-boundary";
import RedirectToLogin from './views/RedirectToLogin'
import { HashRouter } from "react-router-dom";

const tokenLogger = ({ token }) => {
  localStorage.setItem("token", token);
};

const KeycloakTokenHandler = ({ children }) => {
  const [needsLogin, setNeedsLogin] = useState(false);

  useEffect(() => {
    keycloak.onTokenExpired = () => {
      keycloak.updateToken(30).then((refreshed) => {
        if (refreshed) {
          console.log('Token refreshed');
        } else {
          console.log('Token not refreshed, user needs to re-login');
          setNeedsLogin(true);
        }
      }).catch(() => {
        console.log('Failed to refresh token');
        setNeedsLogin(true);
      });
    };
  }, []);

  if (needsLogin) {
    return <RedirectToLogin />;
  }

  return children;
};


createRoot(document.getElementById("root")).render(
  <ErrorBoundary fallback={<RedirectToLogin />}>
  <HashRouter>

    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{
        onLoad: "login-required"
      }}
      onTokens={tokenLogger}
    >
      <Provider store={store}>
        <App />
      </Provider>
      <KeycloakTokenHandler />
    </ReactKeycloakProvider>
    </HashRouter>
  </ErrorBoundary>,
);

reportWebVitals();
